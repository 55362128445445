'use client'

import { IconArrowRight, IconInfoCircleFilled, IconSearch } from '@tabler/icons-react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { getName, registerLocale } from 'i18n-iso-countries'
import { auth, firestore } from '@/providers/FirebaseApp'
import { onAuthStateChanged } from 'firebase/auth'
import { useEffect, useMemo, useState } from 'react'
import { doc } from 'firebase/firestore'
import { LOCALES_KEYS } from '@/i18n'
import { useLocale, useTranslations } from 'next-intl'

import Country from './Country'
import { type Region, type Country as CountryType } from '@/lib/types'
import DataPlan, { DataPlanSkeleton, type Plan } from './DataPlan'
import Input from '@/app/_components/Reusable/Input'
import CountryTag, { CountryTagSkeleton } from './CountryTag'
import Image from 'next/image'
import Link from 'next/link'
import Modal from './Modal'

LOCALES_KEYS.forEach((locale) => registerLocale(require(`i18n-iso-countries/langs/${locale}.json`)))

const MAX_COUNTRIES = 7

interface Operator {
	packages: Plan[]
	countries: AvailableCountry[]
}
const sortPlansByDataSize = (plans: Plan[]): Plan[] => {
	return plans.sort((a, b) => a.amount - b.amount) // Assuming `amount` represents the data size (e.g., 1024 for 1GB, 2048 for 2GB, etc.)
}
const findOperatorWithCheapestPlan = (operators: Operator[]): Operator | null => {
	let cheapestOperator: Operator | null = null
	let lowestPrice = Infinity

	operators.forEach((operator) => {
		operator.packages.forEach((plan) => {
			if (plan.price < lowestPrice) {
				lowestPrice = plan.price
				cheapestOperator = operator
			}
		})
	})

	return cheapestOperator
}

const PlansSection = ({
	dbCountries: countries,
	dbRegions: regions,
}: {
	dbCountries: CountryType[]
	dbRegions: Region[]
}) => {
	const locale = useLocale()
	const t = useTranslations('Plans')

	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)
	const [barcodeSrc, setBarcodeSrc] = useState<string>('')
	const [downloadTitle, setDownloadTitle] = useState<string>('')

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user?.isAnonymous) {
				setIsAuthenticated(true)
			} else {
				setIsAuthenticated(false)
			}
		})

		// Clean up the subscription on unmount
		return () => unsubscribe()
	}, [])

	const [search, setSearch] = useState('')
	const [active, setActive] = useState<string>(regions?.[0]?.slug || countries?.[0]?.country_code || '')

	const isActiveRegion = regions.find((region) => region.slug === active) !== undefined

	const [packages, packagesIsLoading, packagesError] = useDocumentData(
		active && isAuthenticated ? doc(firestore, isActiveRegion ? 'packages_region' : 'packages_local', active) : null
	)

	const activeCountryOrRegion = isActiveRegion
		? regions?.find((region) => region.slug === active)
		: countries?.find((country) => country.country_code === active)

	// let plans: Plan[] = useMemo(
	// 	() => packages?.operators?.flatMap((operator: Operator) => operator.packages) || [],
	// 	[packages]
	// )
	const plans: Plan[] = useMemo(() => {
		if (!packages) return [];
	
		// Find the operator with the cheapest plan
		const cheapestOperator = findOperatorWithCheapestPlan(packages.operators);
	
		// If there's no cheapest operator, return an empty array
		if (!cheapestOperator) return [];
	
		// Mark all plans of the cheapest operator as "Best Price"
		// cheapestOperator.packages.forEach((plan) => {
		// 	plan.isBestPrice = true;
		// });
	
		// Return only the plans from the cheapest operator
		const sortedPlans = cheapestOperator.packages.sort((a, b) => {
			if (a.amount !== b.amount) {
				return a.amount - b.amount;
			} else {
				return a.day - b.day;
			}
		});
		return sortedPlans;
	}, [packages]);

	const uniqueCountries = packages?.operators
		?.flatMap((operator: Operator) => operator.countries)
		.filter((country: any) => country.image?.url.includes('airalo')) // Step 1: Filter countries with "airalo" in the image URL
		.reduce((acc: any, country: any) => {
			acc.set(country.country_code, country) // Step 2: Use a Map to ensure unique country_code
			return acc
		}, new Map())
		.values() // Extract the values from the Map

	const availableCountries: AvailableCountry[] = uniqueCountries ? Array.from(uniqueCountries) : []
	const availableCountriesCount = availableCountries.length
	const shownCountries = availableCountries.slice(0, MAX_COUNTRIES)
	const moreCountries = availableCountriesCount - MAX_COUNTRIES

	const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null)

	useEffect(() => {
		if (plans && (!selectedPlan || !plans.find((plan) => plan.id === selectedPlan.id))) {
			setSelectedPlan(plans[0])
		}
	}, [plans, selectedPlan])

	const handlePlanClick = () => {
		setBarcodeSrc(t('barcode_download'))
		setDownloadTitle(t('download_title'))
		setModalOpen(true)
	}

	const filteredRegions = regions?.filter((region) => region.title.toLowerCase().includes(search.toLowerCase())) || []
	const filteredCountries =
		countries?.filter((country) => country.title.toLowerCase().includes(search.toLowerCase())) || []

	useEffect(() => {
		if (packages) {
			// Find the operator with the cheapest plan
			const cheapestOperator = findOperatorWithCheapestPlan(packages.operators)

            // Mark all plans of the cheapest operator as "Best Price"
            if (cheapestOperator) {
                cheapestOperator.packages.forEach((plan) => {
                    plan.isBestPrice = true;
                });
            }

            // Prioritize the cheapest operator first, then the rest by their cheapest plan
			// const sortedAndPrioritizedOperators = packages.operators.sort((a: Operator, b: Operator) => {
			// 				if (a === cheapestOperator) return -1;
			// 				if (b === cheapestOperator) return 1;
			// 				return 0;
			// 			});
			
			// Filter to include only the cheapest operator
			const filteredOperators = packages.operators.filter((operator: Operator) => operator === cheapestOperator);
			console.log(filteredOperators);
            // Flatten the plans, with the cheapest operator’s plans appearing first
            // const allPlansInOrder: Plan[] = sortedAndPrioritizedOperators.flatMap((operator: Operator) => operator.packages);
			const allPlansInOrder: Plan[] = filteredOperators.flatMap((operator: Operator) => operator.packages);
			console.log("allPlansInOrder", allPlansInOrder);
			// Set the selected plan to the first in the sorted list
			setSelectedPlan(allPlansInOrder[0])
		}
	}, [packages])
	return (
		<div
			className='container space-y-5 mt-16 lg:mt-20'
			id='plans'
		>
			<h2 className='text-center lg:text-start w-full font-bold text-2xl lg:text-3xl text-grayish whitespace-pre'>
				{t('homepage_plans_title').replace('\\n', '\n')}
			</h2>
			<div className='flex flex-col lg:grid lg:grid-cols-5 gap-6'>
				<div className='col-span-2 bg-white rounded-3xl overflow-hidden relative fade-out'>
					<div className='p-6'>
						<Input
							icon={IconSearch}
							value={search}
							onValueChange={setSearch}
							placeholder={t('homepage_plans_searchPlaceholder')}
							id='plans-search'
						/>
					</div>
					<div className='p-6 pt-0 h-full overflow-y-scroll flex flex-col gap-4 max-h-[600px]'>
						{filteredRegions.length > 0 ? (
							<>
								<h3 className='text-grayish text-opacity-30 font-bold text-lg'>
									{t('homepage_plans_regional')}
								</h3>
								<div className='space-y-2'>
									{filteredRegions.map((region) => (
										<Country
											key={region.slug}
											active={active === region.slug}
											setActive={() => setActive(region.slug)}
											{...region}
										/>
									))}
								</div>
							</>
						) : null}
						{filteredCountries.length > 0 ? (
							<>
								<h3 className='text-grayish text-opacity-30 font-bold text-lg'>
									{t('homepage_plans_countries')}
								</h3>
								<div className='space-y-2'>
									{filteredCountries.map((country) => (
										<Country
											key={country.country_code}
											active={active === country.country_code}
											setActive={() => setActive(country.country_code)}
											{...country}
										/>
									))}
								</div>
							</>
						) : null}
					</div>
				</div>
				<div className="col-span-3 gap-3 flex flex-col">
					<div className="py-3 px-4 flex w-full bg-[#0A71FA0D] border-[#0A71FA1A] border rounded-[24px] gap-2 items-center text-[#0A71FA] font-extrabold text-base">
						<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M28.7052 14.9919L26.6785 12.9652C26.3319 12.6185 26.0519 11.9385 26.0519 11.4585V8.57854C26.0519 7.40521 25.0919 6.44521 23.9185 6.44521H21.0519C20.5719 6.44521 19.8919 6.16521 19.5452 5.81854L17.5185 3.79188C16.6919 2.96521 15.3319 2.96521 14.5052 3.79188L12.4519 5.81854C12.1185 6.16521 11.4385 6.44521 10.9452 6.44521H8.07854C6.90521 6.44521 5.94521 7.40521 5.94521 8.57854V11.4452C5.94521 11.9252 5.66521 12.6052 5.31854 12.9519L3.29187 14.9785C2.46521 15.8052 2.46521 17.1652 3.29187 17.9919L5.31854 20.0185C5.66521 20.3652 5.94521 21.0452 5.94521 21.5252V24.3919C5.94521 25.5652 6.90521 26.5252 8.07854 26.5252H10.9452C11.4252 26.5252 12.1052 26.8052 12.4519 27.1519L14.4785 29.1785C15.3052 30.0052 16.6652 30.0052 17.4919 29.1785L19.5185 27.1519C19.8652 26.8052 20.5452 26.5252 21.0252 26.5252H23.8919C25.0652 26.5252 26.0252 25.5652 26.0252 24.3919V21.5252C26.0252 21.0452 26.3052 20.3652 26.6519 20.0185L28.6785 17.9919C29.5452 17.1785 29.5452 15.8185 28.7052 14.9919ZM10.6652 12.4985C10.6652 11.7652 11.2652 11.1652 11.9985 11.1652C12.7319 11.1652 13.3319 11.7652 13.3319 12.4985C13.3319 13.2319 12.7452 13.8319 11.9985 13.8319C11.2652 13.8319 10.6652 13.2319 10.6652 12.4985ZM12.7052 21.2052C12.5052 21.4052 12.2519 21.4985 11.9985 21.4985C11.7452 21.4985 11.4919 21.4052 11.2919 21.2052C10.9052 20.8185 10.9052 20.1785 11.2919 19.7919L19.2919 11.7919C19.6785 11.4052 20.3185 11.4052 20.7052 11.7919C21.0919 12.1785 21.0919 12.8185 20.7052 13.2052L12.7052 21.2052ZM19.9985 21.8319C19.2519 21.8319 18.6519 21.2319 18.6519 20.4985C18.6519 19.7652 19.2519 19.1652 19.9852 19.1652C20.7185 19.1652 21.3185 19.7652 21.3185 20.4985C21.3185 21.2319 20.7319 21.8319 19.9985 21.8319Z" fill="#0A71FA"/>
						</svg>
						<span>{t('homepage_plans_cheaper')}</span>
						</div>
					<div className='bg-white rounded-3xl flex flex-col gap-6 p-6'>
						{activeCountryOrRegion && (
							<>
								<div className='flex justify-between items-start gap-6'>
									<div>
										<h3 className='text-2xl font-bold text-grayish'>
											{activeCountryOrRegion.title} eSIM
										</h3>
										<h4 className='text-xl font-semibold text-grayish text-opacity-40'>
											{t('homepage_plans_subtitle')}
										</h4>
									</div>
									<Image
										src={activeCountryOrRegion.image}
										className='w-[90px] h-14 country-flag'
										alt='logo'
										width={80}
										height={54}
									/>
								</div>
								<div className='space-y-3'>
									<h5 className='font-semibold text-grayish text-opacity-40'>
										{t('homepage_plans_choose')}
									</h5>
									<div className='flex gap-2 overflow-x-auto'>
										{plans
											? plans.map((plan) => (
													<DataPlan
														key={plan.id}
														active={selectedPlan?.id === plan.id}
														onClick={() => setSelectedPlan(plan)}
														isBestPrice={plan.isBestPrice}
														isVoiceAndData={
															!!plan.voice && plan.voice > 0 && plan.id !== selectedPlan?.id
														}
														{...plan}
													/>
											))
											: Array.from({ length: 6 }).map((_, i) => <DataPlanSkeleton key={i} />)}
									</div>
								</div>
								<div className='w-full rounded-[30px] font-semibold text-grayish flex flex-col gap-3 lg:gap-6 p-6 bg-[#0A71FA0D]'>
									<div className='flex justify-between'>
										<div className='flex flex-col items-center mx-auto lg:items-start lg:mx-0'>
											<span className='text-grayish text-opacity-30'>
												{t('homepage_plans_price')}
											</span>
											<h3 className='font-bold text-4xl'>${selectedPlan?.price}</h3>
										</div>
										<div className='hidden lg:flex gap-3 text-sm items-start'>
											<Link
												href='#'
												className='flex items-center text-grayish text-opacity-30 gap-1'
											>
												<IconInfoCircleFilled size={13.3} />
												{t('homepage_plans_details')}
											</Link>
											<Link
												href='#'
												className='flex items-center text-grayish text-opacity-30 gap-1'
											>
												<IconInfoCircleFilled size={13.3} />
												{t('homepage_plans_about')}
											</Link>
										</div>
									</div>
									<button
										className='bg-gradient shadow-gradient rounded-full py-6 text-white font-bold text-xl flex gap-2 items-center justify-center'
										onClick={handlePlanClick}
									>
										{t('homepage_plans_buy_for')} ${selectedPlan?.price}{' '}
										<IconArrowRight
											size={24}
											className='rtl:-scale-x-100'
										/>
									</button>
									<div className='lg:hidden flex gap-3 text-sm items-start mx-auto'>
										<Link
											href='#'
											className='flex items-center text-grayish text-opacity-30 gap-1'
										>
											<IconInfoCircleFilled size={13.3} />
											{t('homepage_plans_details')}
										</Link>
										<Link
											href='#'
											className='flex items-center text-grayish text-opacity-30 gap-1'
										>
											<IconInfoCircleFilled size={13.3} />
											{t('homepage_plans_about')}
										</Link>
									</div>
								</div>
								<div className='space-y-3 min-h-[85px]'>
									<span className='font-semibold text-grayish text-opacity-40'>
										{t('homepage_plans_also_available')}
									</span>
									<div className='flex gap-2 overflow-x-auto'>
										{shownCountries.length > 0
											? shownCountries.map((country) => (
													<CountryTag
														onClick={() => setActive(country.country_code)}
														key={country.country_code}
													>
														{getName(country.country_code, locale) || country.title}
													</CountryTag>
											))
											: Array.from({ length: MAX_COUNTRIES }).map((_, i) => (
													<CountryTagSkeleton key={i} />
											))}
										{moreCountries > 0 ? (
											<CountryTag primary>
												+{t('homepage_plans_more', { count: moreCountries })}
											</CountryTag>
										) : (
											<></>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<Modal
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				barcodeSrc={barcodeSrc}
				downloadTitle={downloadTitle}
			/>
		</div>
	)
}

interface AvailableCountry {
	country_code: string
	title: string
	image: {
		width: number
		height: number
		url: string
	}
}

export default PlansSection
